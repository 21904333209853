import { ApolloLink } from '@apollo/client';
import { storageKey } from '../context';

const authLink = new ApolloLink((operation, forward) => {
  const { useAuth } = operation.getContext();
  if (!useAuth) {
    return forward(operation);
  }

  const token = localStorage.getItem(storageKey);
  if (token) {
    operation.setContext(({ headers }) => ({
      headers: {
        authorization: `Bearer ${token}`,
        ...headers,
      },
    }));
  }
  return forward(operation);
});

export default authLink;
