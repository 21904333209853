/* eslint-disable import/prefer-default-export */

import React from 'react';
import Provider from './src/context';

export const wrapPageElement = ({ element }, {
  tokenKey,
  redirectToAfterRegister,
}) => (
  <Provider
    tokenKey={tokenKey}
    redirectToAfterRegister={redirectToAfterRegister}
  >
    {element}
  </Provider>
);
